import { useDispatch } from 'react-redux';
import { firstBy } from 'thenby';

import { ENDORSEMENT_CHANGE_TYPE, RISK_QUOTE_STATUS_BOUND, RISK_QUOTE_STATUS_PENDING, RISK_QUOTE_STATUS_QUOTING } from 'consts';
import { QB_RISK_QUOTES, useGetEndorsements, useGetQuotes } from 'lib/quoteBind';
import { showModal } from 'stores';
import * as utils from 'utils';

// app
import { RiskPolicyView } from './RiskPolicy.view';

export const RiskPolicy = ({ risk, riskIsLoading }) => {
  const dispatch = useDispatch();
  const { data: quotes, isLoading } = useGetQuotes(QB_RISK_QUOTES, risk?.id, {
    enabled: !!risk?.id,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const { data: endorsements } = useGetEndorsements(risk?.id, {
    enabled: !!risk?.id,
    refetchInterval: (data) => {
      const isQuoting = data?.some((quote) => quote.status === RISK_QUOTE_STATUS_QUOTING);
      return isQuoting ? 2000 : false;
    },
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  const effectiveEndorsements = endorsements?.filter((endorsement) => endorsement.status === RISK_QUOTE_STATUS_BOUND) || [];
  const effectiveEndorsementsSorted =
    utils.sort
      .arrayNestedPropertyValue(effectiveEndorsements, 'response.responseDate', 'asc')
      ?.map((endorsement, index) => ({
        ...endorsement,
        index: index + 1,
      }))
      .sort(firstBy(utils.sort.array('date', 'effectiveFrom', 'asc'))) || [];

  const pendingEndorsements = endorsements?.filter((endorsement) => endorsement.status === RISK_QUOTE_STATUS_PENDING) || [];
  const quotedEndorsements =
    endorsements?.filter((endorsement) => ![RISK_QUOTE_STATUS_PENDING, RISK_QUOTE_STATUS_BOUND].includes(endorsement.status)) || [];

  const cancellationEndorsement =
    [...effectiveEndorsements, ...pendingEndorsements].find(
      (endorsement) => endorsement.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION
    ) || null;

  const extensionEndorsement =
    [...effectiveEndorsements, ...pendingEndorsements].find(
      (endorsement) => endorsement.changeType === ENDORSEMENT_CHANGE_TYPE.EXTENSION
    ) || null;

  const isCanceled = Boolean(cancellationEndorsement);
  const isExtended = Boolean(extensionEndorsement);

  const latestEffectiveEndorsementDate = cancellationEndorsement?.effectiveFrom || null;

  const hasBoundQuote =
    utils.generic.isValidArray(quotes, true) && quotes?.some((q) => q.response && q.response.responseStatus === RISK_QUOTE_STATUS_BOUND);

  const policy = quotes?.find((q) => q.response && q.response.responseStatus === RISK_QUOTE_STATUS_BOUND) || null;
  const policyCurrency = policy?.currency || null;
  const hasSurplusLineTax = risk?.hasSurplusLineTax ?? false;
  const hasBackdateCancellation = Boolean(risk?.hasCancellationBackdate);
  const hasBackdateEndorsement = Boolean(risk?.hasEndorsementBackdate);
  const hasEndorsementAdditionalPremium = Boolean(risk?.hasEndorsementAdditionalPremium);

  const executePreBind = (quote, isPreBind, isReqBind) => {
    const product = risk.riskType;
    const modalTitle = isPreBind ? `${utils.string.t('risks.preBind')}` : `${utils.string.t('risks.postBind')}`;

    dispatch(
      showModal({
        component: 'PRE_BIND_QUOTE',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'lg',
          componentProps: {
            product,
            risk,
            quote,
            isPreBind,
            isReqBind,
          },
        },
      })
    );
  };

  const handlePreBind = (quote, isPreBind) => (event) => {
    executePreBind(quote, isPreBind, false);
  };

  const handleAddEndorsement = (endorsementRisk, effectiveDate) => {
    const { riskType, id: riskId, risk: riskData } = { ...endorsementRisk };
    const effectiveDateText = utils.string.t('format.date', {
      value: { date: effectiveDate, format: 'll', default: '-' },
    });
    const modalTitle = `${utils.string.t('app.addEndorsement')} - ${effectiveDateText} - ${endorsementRisk?.insured?.name}`;

    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product: { value: riskType },
            riskData,
            riskId,
            addEndorsement: true,
            effectiveDate,
          },
        },
      })
    );
  };

  const handleReQuoteEndorsement = (endorsementId, effectiveDate) => {
    const riskId = risk.id;
    const riskData = risk.risk;
    const { riskType } = risk;
    const modalSubtitle = utils.string.t('app.requoteEndorsement');
    const effectiveDateText = utils.string.t('format.date', {
      value: { date: effectiveDate, format: 'll', default: '-' },
    });
    const modalTitle = `${modalSubtitle} - ${effectiveDateText} - ${risk?.insured?.name}`;

    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product: { value: riskType },
            riskData,
            riskId,
            addEndorsement: true,
            effectiveDate,
            endorsementId,
          },
        },
      })
    );
  };
  return (
    <RiskPolicyView
      isLoading={isLoading}
      riskIsLoading={riskIsLoading}
      policy={policy}
      isCanceled={isCanceled}
      isExtended={isExtended}
      policyCurrency={policyCurrency}
      effectiveEndorsements={effectiveEndorsementsSorted}
      pendingEndorsements={pendingEndorsements}
      quotedEndorsements={quotedEndorsements}
      latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
      hasBoundQuote={hasBoundQuote}
      hasBackdateCancellation={hasBackdateCancellation}
      hasBackdateEndorsement={hasBackdateEndorsement}
      hasSurplusLineTax={hasSurplusLineTax}
      hasEndorsementAdditionalPremium={hasEndorsementAdditionalPremium}
      handlePreBind={handlePreBind}
      handleAddEndorsement={handleAddEndorsement}
      handleReQuoteEndorsement={handleReQuoteEndorsement}
    />
  );
};
