import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import { Box, Card, CardActions, CardContent, CardHeader, Drawer, Popover, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// MUI
import { useTheme } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import ForwardIcon from '@material-ui/icons/Forward';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UpdateIcon from '@material-ui/icons/Update';

import { Avatar, Button, FormGrid, Restricted, Status, Tooltip, Translate } from 'components';
import { QuoteIssues } from 'modules';

import {
  QUOTE_PREMIUM_PRECISION,
  RISK_QUOTE_STATUS_BLOCKED,
  RISK_QUOTE_STATUS_DECLINED,
  RISK_QUOTE_STATUS_NTU,
  RISK_QUOTE_STATUS_QUOTED,
  RISK_QUOTE_STATUS_REFERRED,
  RISK_QUOTE_STATUS_REJECTED,
  ROLE_BROKER,
  ROLE_PRODUCER,
  ROLE_UNDERWRITER,
} from 'consts';
import {
  useAcceptQuote,
  useDeclineQuote,
  useDownloadQuote,
  usePutQuoteRates,
  useReActivateQuote,
  useRequestDismissIssue,
} from 'lib/quoteBind/';
import { showModal } from 'stores';
import * as utils from 'utils';

import { AdditionalOptions } from '../AdditionalOptions';
import { BindPopover } from '../BindPopover';
import CommissionSlider from '../CommissionSlider';

import { QuoteCardSkeleton } from './QuoteCardSkeleton';

// app
import { useQuoteCardStyles } from './QuoteCard.styles';
// app

const QuoteLine = ({
  title,
  value,
  editIcon,
  isEditDisabled,
  justifyTitle = 'space-between',
  justifyValue = 'space-between',
  alignItems = 'center',
  handleEdit,
}) => {
  const classes = useQuoteCardStyles();

  return (
    <>
      <FormGrid item xs={5}>
        <Box display="flex" justifyContent={justifyTitle} alignItems={alignItems} height="100%">
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        </Box>
      </FormGrid>
      <FormGrid item xs={7}>
        <Box display="flex" justifyContent={justifyValue} alignItems={alignItems}>
          <Typography variant="body2" className={classes.value}>
            {value}
          </Typography>
          {editIcon && (
            <Button
              data-testid="edit-quote-button"
              size="xsmall"
              icon={EditIcon}
              variant="text"
              nestedClasses={{ icon: classes.icon }}
              onClick={handleEdit}
              disabled={isEditDisabled}
            />
          )}
        </Box>
      </FormGrid>
    </>
  );
};

export const quoteInformation = (quoteInfo, isQuoted) => (
  <FormGrid container spacing={1} justifyContent="center" alignItems="start" data-testid="quote-summary-info" style={{ minWidth: 150 }}>
    {quoteInfo.map((info, index) => (
      <Restricted
        key={info.title}
        include={info?.hideForProducers && !isQuoted ? [ROLE_BROKER, ROLE_UNDERWRITER] : [ROLE_BROKER, ROLE_PRODUCER, ROLE_UNDERWRITER]}
      >
        <QuoteLine key={info.title} title={info.title} value={info.value} justifyTitle="end" justifyValue="start" alignItems="start" />
      </Restricted>
    ))}
  </FormGrid>
);

const getOptionalsArray = (quote) => {
  if (quote?.commission?.optionsCommissionList) {
    return quote?.additionalOptions?.map((addOn) => {
      const revisedPremium =
        quote?.commission?.optionsCommissionList?.find((option) => option.name === addOn?.name)?.revisedPremium ?? addOn?.value;
      const selectedOpt = { ...addOn, revisedValue: revisedPremium };
      return selectedOpt;
    });
  }
  return quote?.additionalOptions;
};

export const QuoteCard = ({
  quote,
  riskClientCommission,
  riskInceptionDate,
  riskExpiryDate,
  issuesData,
  handleBindRiskQuote,
  handleRiskRefresh,
  handleRequestToBind,
  hasSurplusLineTax = false,
}) => {
  const classes = useQuoteCardStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { mutate: declineQuote } = useDeclineQuote();
  const { mutate: acceptQuote, isLoading: isLoadingAcceptQuote } = useAcceptQuote();
  const { mutateAsync: downloadRiskQuote } = useDownloadQuote();
  const { mutateAsync: putQuoteRates } = usePutQuoteRates();
  const { mutateAsync: requestDismissIssues } = useRequestDismissIssue();
  const { mutateAsync: reActivateQuote, isLoading: isLoadingReActivate } = useReActivateQuote();

  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [uuid, setUuid] = useState(new Date().getTime());
  const [quoteState, setQuoteState] = useState(quote);
  const [quotePremium, setQuotePremium] = useState(
    quote?.commission?.revisedPremium ? quote.commission?.revisedPremium : quote.grossPremium
  );
  const [quoteOptionals, setQuoteOptionals] = useState(
    quote?.commission?.optionsCommissionList?.map((opt) => opt?.revisedPremium)?.reduce((opt1, opt2) => opt1 + opt2, 0) ?? 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElBind, setAnchorElBind] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [commissionIsChanged, setCommissionIsChanged] = useState(false);
  const [commission, setCommission] = useState({
    commissionRatioClient: quote?.commission?.clientCommissionRatio,
    netDownClientCommission: quote?.commission?.clientCommissionRate,
  });

  const [additionalOptions, setAdditionalOptions] = useState(getOptionalsArray(quote));

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  useEffect(() => {
    setQuoteState(quote);
    setQuotePremium(quote?.commission?.revisedPremium ? quote?.commission?.revisedPremium : quote?.grossPremium);
    setCommission({
      commissionRatioClient: quote?.commission?.clientCommissionRatio,
      netDownClientCommission: quote?.commission?.clientCommissionRate,
    });
    setQuoteOptionals(
      quote?.commission?.optionsCommissionList?.map((opt) => opt?.revisedPremium)?.reduce((opt1, opt2) => opt1 + opt2, 0) ?? 0
    );
    const newOptions = getOptionalsArray(quote);
    setAdditionalOptions(newOptions);
  }, [quote]);

  const getCurrencyValue = useCallback(
    (value) =>
      `${quoteState?.currency ? utils.string.t(quoteState.currency) : ''} ${utils.string.t('format.number', {
        value: { number: value, default: '-' },
      })}`,
    [quoteState]
  );

  const createdAt = utils.string.t('format.date', {
    value: { date: quoteState.createdAt, format: 'll' },
  });
  const carrierName = get(quoteState, 'carrierName');
  const quoteStatus = quoteState.response ? quoteState.response.responseStatus : quoteState.status;
  let quoteStatusString = quoteStatus ? utils.string.t(`QBstatus.${quoteStatus.toLowerCase()}`) : '-';
  quoteStatusString = quoteStatusString === 'Quoted' ? utils.string.t('status.quote') : quoteStatusString;
  const isBlocked = quoteStatus === RISK_QUOTE_STATUS_BLOCKED;
  const isExpired = quoteState.validUntil && utils.date.isBefore(quoteState.validUntil);
  const isQuoted = quoteStatus === RISK_QUOTE_STATUS_QUOTED;
  const isReferred = quoteStatus === RISK_QUOTE_STATUS_REFERRED;
  const isRejected = quoteStatus === RISK_QUOTE_STATUS_REJECTED;
  const isDeclined = quoteStatus === RISK_QUOTE_STATUS_DECLINED;
  const isNTU = quoteStatus === RISK_QUOTE_STATUS_NTU;
  const { hasTemplate } = quoteState;

  const quoteInfo = quoteState?.summaryValues || [];
  const isBindable = quoteState?.bindable;
  const showReferIssues = (isBlocked || isRejected || (isReferred && issuesData?.hasIssues)) && !isDeclined;

  const defaultClientCommissionRate = isNumber(riskClientCommission?.clientCommissionRate)
    ? riskClientCommission.clientCommissionRate
    : quoteState?.facility?.commissionRates?.clientCommissionRate;
  const defaultBrokerCommissionRate = isNumber(riskClientCommission?.brokerCommissionRate)
    ? riskClientCommission.brokerCommissionRate
    : quoteState?.facility?.commissionRates?.brokerCommissionRate;

  const clientCommissionRatio = isNumber(quoteState?.commission?.clientCommissionRatio)
    ? quoteState.commission.clientCommissionRatio
    : defaultClientCommissionRate;
  const clientCommissionRate = isNumber(quoteState?.commission?.clientCommissionRate)
    ? quoteState.commission.clientCommissionRate
    : defaultClientCommissionRate;

  const isQuoteCommission = isNumber(clientCommissionRate) && isNumber(clientCommissionRatio);
  const isDefaultCommission = isNumber(defaultClientCommissionRate) && isNumber(defaultBrokerCommissionRate);

  const grossPremium = quoteState?.grossPremium;

  const premiumValue = getCurrencyValue(quotePremium);

  const surplusLineTax = getCurrencyValue(quoteState?.quoteValues?.surplusLineTax);

  const premiumValueProducer =
    isBlocked || isReferred || isDeclined || isRejected
      ? utils.string.t(`products.premiumPlaceholder.${quoteStatus.toLowerCase()}`)
      : premiumValue;
  const canCurrentUserBind = quoteState?.canCurrentUserBind;
  const requestedToBind = quoteState?.requestedToBind;
  const displayPreBind = quoteState?.facility?.preBind;
  const canCurrentUserDismissIssues = quoteState?.canCurrentUserDismissIssues;

  const requestedToDismissIssues = quoteState?.requestedToDismissIssues;
  const earliestBindPeriod = quoteState?.facility?.earliestBindPeriod;
  const canEditQuote = (isReferred || isQuoted) && canCurrentUserDismissIssues && !requestedToBind && !issuesData?.hasIssues;
  const canEditAddOns =
    (isReferred || isQuoted) && !requestedToBind && !issuesData?.hasIssues && !(isBlocked || commissionIsChanged || isWaiting);
  const handleUpdateCommission = async () => {
    setIsLoading(true);
    const rates = {
      clientCommissionRatio: commission.commissionRatioClient,
      clientCommissionRate: commission.netDownClientCommission,
      scale: QUOTE_PREMIUM_PRECISION,
    };
    const result = await putQuoteRates({ commissionRates: rates, quoteID: quoteState.id });
    if (result) {
      setQuoteState(result);
    }
    setIsLoading(false);
  };

  const handleCommissionChange = (newCommission, isChanged) => {
    setCommissionIsChanged(isChanged);
    setCommission(newCommission);
    const commissionRatioClient = isNumber(newCommission?.commissionRatioClient)
      ? newCommission?.commissionRatioClient
      : defaultClientCommissionRate;
    const commissionRate =
      commissionRatioClient === newCommission.netDownClientCommission
        ? 1
        : (1 - commissionRatioClient * 0.01) / (1 - newCommission.netDownClientCommission * 0.01);

    let grossOptionals = 0;
    if (quoteState?.commission?.optionsCommissionList) {
      grossOptionals = quoteState?.additionalOptions?.map((opt) => opt?.value)?.reduce((opt1, opt2) => opt1 + opt2, 0) ?? 0;
      const newOptionals = parseFloat((grossOptionals * commissionRate).toFixed(QUOTE_PREMIUM_PRECISION));
      setQuoteOptionals(Math.round(newOptionals));
    }

    const newPremium = parseFloat(((grossPremium + grossOptionals) * commissionRate).toFixed(QUOTE_PREMIUM_PRECISION));
    const newAddOns = additionalOptions?.map((addon) => ({
      ...addon,
      revisedValue: Math.round(parseFloat(addon.value * commissionRate).toFixed(QUOTE_PREMIUM_PRECISION)),
    }));

    setQuotePremium(Math.round(newPremium));
    setAdditionalOptions(newAddOns);
  };

  const resetCommission = () => {
    setUuid(new Date().getTime());
    setCommissionIsChanged(false);
    const premium = quoteState?.commission?.revisedPremium ? quoteState?.commission?.revisedPremium : quoteState.grossPremium;
    setQuotePremium(premium);
  };

  const handleUpdateAddOns = (newQuote) => {
    setQuoteState(newQuote);
  };

  const handleIssuesPopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCloseBindPopover = () => {
    setAnchorElBind(null);
  };

  const toggleDrawer = (value) => {
    setDrawerOpen(value);
  };

  const handleRiskRefreshAndCloseDrawer = () => {
    handleRiskRefresh();
    toggleDrawer(false);
  };

  const handleRequestDismissIssues = async (quoteId) => {
    setIsWaiting(true);
    const result = await requestDismissIssues({ quoteId });
    if (result) {
      setQuoteState(result);
    }
    setIsWaiting(false);
  };

  const handlePatchRiskQuote = ({ quoteProp }) => {
    dispatch(
      showModal({
        component: 'EDIT_RISK_QUOTE',
        props: {
          title: 'risks.updateQuote',
          fullWidth: true,
          maxWidth: 'xs',
          componentProps: {
            quote: quoteProp,
          },
        },
      })
    );
  };

  const handleBindClick = async (e) => {
    e.stopPropagation();
    setAnchorElBind(e.currentTarget);
  };

  const handleBindQuoteAndClosePopover = (selectedInceptionDate, selectedExpiryDate) => {
    setAnchorElBind(null);
    handleBindRiskQuote(quote, selectedInceptionDate, selectedExpiryDate, displayPreBind);
  };

  const handleAcceptRiskQuote = (id) => {
    acceptQuote({ id });
  };

  const handleReactivateRiskQuote = (id) => {
    reActivateQuote({ id });
  };

  const handleDownloadQuote = async (id) => {
    setIsDownloading(true);
    setIsWaiting(true);
    await downloadRiskQuote({ id });
    setIsDownloading(false);
    setIsWaiting(false);
  };

  const handleDeclineRiskQuote = (id, isNtu = false) => {
    dispatch(
      showModal({
        component: 'CONFIRM_DECLINE_RISK',
        props: {
          modalProps: { fullWidth: true, maxWidth: 'md' },
          title: isNtu ? 'risks.ntuQuote' : 'risks.declineQuote',
          maxWidth: 'xs',
          componentProps: {
            submitHandler: () => {
              declineQuote({ id });
            },
            isNtu,
          },
        },
      })
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'issue-popover' : undefined;

  const openBind = Boolean(anchorElBind);
  const idBind = openBind ? 'bind-popover' : undefined;

  const statusBackgroundColor = theme.palette.status[quoteStatus?.toLowerCase()]
    ? theme.palette.status[quoteStatus.toLowerCase()]
    : theme.palette.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

  const downloadTemplate = hasTemplate ? (
    <Box position="relative">
      <Button
        data-testid="download-quote-button"
        size="xsmall"
        icon={!isDownloading ? PictureAsPdfIcon : null}
        color="secondary"
        text={utils.string.t('risks.download')}
        nestedClasses={{ btn: isDownloading ? classes.buttonDownloading : '', icon: classes.icon }}
        disabled={isDownloading}
        onClick={() => handleDownloadQuote(quoteState.id)}
      />
      {isDownloading ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
    </Box>
  ) : null;

  const subheader = (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {requestedToBind && isQuoted ? (
        <Status
          size="xs"
          text={<Translate label={utils.string.t('risks.requestToBindStatus')} />}
          status="quoted"
          data-testid="quote-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
          }}
        />
      ) : (
        <Status
          size="xs"
          text={<Translate label={`${quoteStatusString}`} />}
          status={quoteStatus.toLowerCase()}
          data-testid="quote-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
          }}
        />
      )}
    </Box>
  );

  const cardTitle = ({ carrierName, quoteInfo, isQuoted }) => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3" className={classes.cardFacility} style={{ textAlign: 'left' }}>
        {carrierName}
      </Typography>
      {quoteInfo?.length ? (
        <Tooltip title={quoteInformation(quoteInfo, isQuoted)} rich interactive style={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlinedIcon classes={{ root: classes.infoIcon }} data-testid="quote-summary-icon" />
        </Tooltip>
      ) : null}
    </Box>
  );

  if (isLoading) return <QuoteCardSkeleton height={height} />;

  return (
    <>
      {isQuoted ? (
        <BindPopover
          idBind={idBind}
          openBind={openBind}
          anchorElBind={anchorElBind}
          handleCloseBindPopover={handleCloseBindPopover}
          handleBindQuoteAndClosePopover={handleBindQuoteAndClosePopover}
          riskInceptionDate={riskInceptionDate}
          riskExpiryDate={riskExpiryDate}
          earliestBindPeriod={earliestBindPeriod}
          enablePreBindConfirmation={quoteState?.facility?.enablePreBindConfirmation ?? false}
          preBindConfirmationText={quoteState?.facility?.preBindConfirmationText}
        />
      ) : null}
      {isBlocked || isRejected || isReferred ? (
        <Drawer anchor="right" open={drawerOpen} style={{ zIndex: 10000 }} onClose={() => toggleDrawer(false)}>
          <Box p={2}>
            <Box value="issues" data-testid="tab-content-issues">
              {issuesData?.hasIssues && (
                <QuoteIssues
                  riskIssues={issuesData?.issues || []}
                  quoteIssues={issuesData?.quoteIssues || []}
                  insuredSanctionsCheckResult={issuesData?.insuredSanctionsCheckResult}
                  reInsuredSanctionsCheckResult={issuesData?.reInsuredSanctionsCheckResult}
                  canCurrentUserDismissIssues={canCurrentUserDismissIssues}
                  handleRiskRefresh={handleRiskRefreshAndCloseDrawer}
                  quoteId={quote?.id}
                />
              )}
            </Box>
          </Box>
        </Drawer>
      ) : null}
      <Card ref={ref} classes={{ root: classes.card }}>
        {carrierName || createdAt ? (
          <CardHeader
            avatar={<Avatar icon={HowToVoteIcon} size={32} border />}
            title={cardTitle({ carrierName, quoteInfo, isQuoted })}
            subheader={subheader}
            style={{
              borderBottom: `4px solid ${statusBackgroundColor}`,
            }}
          />
        ) : null}
        <CardContent classes={{ root: classes.cardContent }}>
          <FormGrid container spacing={1}>
            <Restricted include={[ROLE_BROKER, ROLE_UNDERWRITER]}>
              <QuoteLine
                title={utils.string.t('risks.grossPremium')}
                value={premiumValue}
                editIcon={canEditQuote}
                isEditDisabled={isBlocked || commissionIsChanged || isWaiting}
                handleEdit={() =>
                  handlePatchRiskQuote({
                    quoteProp: quoteState,
                  })
                }
              />
            </Restricted>
            <Restricted include={[ROLE_PRODUCER]}>
              <QuoteLine title={utils.string.t('risks.grossPremium')} value={premiumValueProducer} />
            </Restricted>
            {hasSurplusLineTax ? (
              <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                <QuoteLine title={utils.string.t('risks.surplusLineTax')} value={surplusLineTax} />
              </Restricted>
            ) : null}
            {quoteState?.commission?.optionsCommissionList && (
              <QuoteLine title={utils.string.t('products.totalOptions')} value={getCurrencyValue(quoteOptionals)} />
            )}
            <QuoteLine
              title={utils.string.t('app.createdAt')}
              value={utils.string.t('format.date', {
                value: { date: quoteState.createdAt, format: 'll', default: '-' },
              })}
            />
            <QuoteLine
              title={utils.string.t('app.validUntil')}
              value={utils.string.t('format.date', {
                value: { date: quoteState.validUntil, format: 'll', default: '-' },
              })}
            />
          </FormGrid>
        </CardContent>

        {additionalOptions?.length > 0 && (
          <AdditionalOptions
            currency={quoteState?.currency}
            additionalOptions={additionalOptions}
            quoteID={quote?.id}
            isEditable={canEditAddOns}
            handleUpdateAddOns={handleUpdateAddOns}
          />
        )}
        {isQuoted && !isBlocked && isQuoteCommission && isDefaultCommission ? (
          <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
            <CardContent classes={{ root: classes.cardContentCommission }}>
              <CommissionSlider
                key={uuid}
                defaultClientCommission={defaultClientCommissionRate}
                defaultBrokerCommission={defaultBrokerCommissionRate}
                netDownClientCommission={clientCommissionRate}
                commissionRatioClient={clientCommissionRatio}
                riskType={quoteState?.riskType ?? ''}
                handleChangedValues={handleCommissionChange}
                isSliderDisabled={requestedToBind}
              />
            </CardContent>
          </Restricted>
        ) : null}
        {commissionIsChanged ? (
          <CardActions disableSpacing classes={{ root: classes.cardActions }}>
            <Box px={1} pb={0.5} mb={1} display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box display="flex" width="100%" flexDirection="row" justifyContent="flex-end">
                <Button
                  data-testid="commission-done-button"
                  size="xsmall"
                  icon={DoneIcon}
                  color="primary"
                  nestedClasses={{ icon: classes.icon }}
                  onClick={handleUpdateCommission}
                />
                <Button
                  data-testid="cancel-commission-button"
                  size="xsmall"
                  icon={ClearIcon}
                  color="default"
                  nestedClasses={{ btn: classes.btnMarginLeft, icon: classes.icon }}
                  onClick={resetCommission}
                />
              </Box>
            </Box>
          </CardActions>
        ) : (
          <>
            {isQuoted && !isBlocked ? (
              <CardActions disableSpacing classes={{ root: classes.cardActions }}>
                <Box px={1} pb={0.5} display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                  {downloadTemplate}
                  <Box
                    display="flex"
                    flexDirection="row"
                    ml="auto"
                    flexWrap={canCurrentUserBind ? 'no-wrap' : 'wrap'}
                    justifyContent="flex-end"
                  >
                    {canCurrentUserBind && (
                      <>
                        <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                          <Box mb={1}>
                            <Tooltip title={isBindable ? '' : utils.string.t('products.notBindable', { days: earliestBindPeriod })} rich>
                              <Button
                                data-testid="bind-quote-button"
                                size="xsmall"
                                style={{ backgroundColor: 'rgb(44, 198, 171)' }}
                                icon={CheckIcon}
                                color="primary"
                                text={utils.string.t('risks.bind')}
                                nestedClasses={{ icon: classes.icon }}
                                onClick={(e) => handleBindClick(e)}
                                disabled={isBlocked || isWaiting || !isBindable}
                              />
                            </Tooltip>
                          </Box>
                        </Restricted>
                        <Restricted include={[ROLE_UNDERWRITER]}>
                          <Box mb={1}>
                            <Tooltip title={isBindable ? '' : utils.string.t('products.notBindable', { days: earliestBindPeriod })} rich>
                              <Button
                                data-testid="bind-quote-button"
                                size="xsmall"
                                style={{ backgroundColor: 'rgb(44, 198, 171)' }}
                                icon={CheckIcon}
                                color="primary"
                                text={utils.string.t('risks.bind')}
                                nestedClasses={{ icon: classes.icon }}
                                onClick={(e) => handleBindClick(e, quoteState, false)}
                                disabled={isBlocked || isWaiting || !isBindable}
                              />
                            </Tooltip>
                          </Box>
                        </Restricted>
                      </>
                    )}

                    {!canCurrentUserBind && !requestedToBind && (
                      <Box whiteSpace="nowrap" mb={1}>
                        <Button
                          data-testid="request-bind-quote-button"
                          size="xsmall"
                          style={{ backgroundColor: 'rgb(44, 198, 171)' }}
                          icon={CheckIcon}
                          color="primary"
                          text={utils.string.t('risks.requestToBind')}
                          nestedClasses={{ icon: classes.icon }}
                          onClick={() => handleRequestToBind(quoteState, displayPreBind)}
                          disabled={isBlocked || isWaiting}
                        />
                      </Box>
                    )}

                    {isExpired && <Button size="xsmall" color="default" text={utils.string.t('app.expired')} disabled />}
                    <Restricted include={[ROLE_BROKER, ROLE_PRODUCER, ROLE_UNDERWRITER]}>
                      {!isExpired && !isBlocked ? (
                        <Box ml={1}>
                          <Button
                            data-testid="ntu-quote-button"
                            size="xsmall"
                            icon={NotInterestedIcon}
                            color="primary"
                            text={utils.string.t('risks.ntu')}
                            nestedClasses={{ btn: classes.ntu, icon: classes.icon }}
                            onClick={() => handleDeclineRiskQuote(quoteState.id, true)}
                            disabled={isWaiting}
                          />
                        </Box>
                      ) : null}
                    </Restricted>
                  </Box>
                </Box>
              </CardActions>
            ) : null}
            {isReferred && canCurrentUserDismissIssues && !issuesData?.hasIssues ? (
              <CardActions disableSpacing classes={{ root: classes.cardActions }}>
                <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                  {canCurrentUserDismissIssues ? downloadTemplate : null}
                  <Box ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end">
                    <Box mr={1} mb={1}>
                      <Button
                        data-testid="decline-quote-button"
                        size="xsmall"
                        icon={BlockIcon}
                        color="primary"
                        danger
                        text={utils.string.t('risks.decline')}
                        nestedClasses={{ icon: classes.icon }}
                        onClick={() => handleDeclineRiskQuote(quoteState.id)}
                        disabled={isBlocked || isWaiting || isLoadingAcceptQuote}
                      />
                    </Box>

                    <Box mb={1}>
                      <Button
                        data-testid="accept-quote-button"
                        size="xsmall"
                        icon={CheckIcon}
                        color="primary"
                        text={utils.string.t('risks.accept')}
                        nestedClasses={{ icon: classes.icon }}
                        onClick={() => handleAcceptRiskQuote(quoteState.id)}
                        disabled={isBlocked || isWaiting || isLoadingAcceptQuote}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardActions>
            ) : null}
            {showReferIssues ? (
              <CardActions disableSpacing classes={{ root: classes.cardActions }}>
                <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                  {canCurrentUserDismissIssues && isReferred ? downloadTemplate : null}
                  <Box ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end">
                    {!canCurrentUserDismissIssues && !requestedToDismissIssues && isBlocked && quoteState?.id ? (
                      <Box mr={1} mb={1}>
                        <Button
                          data-testid="refer-quote-button"
                          size="xsmall"
                          style={{ backgroundColor: isWaiting ? '' : theme.palette.status.referred, color: 'white' }}
                          icon={ForwardIcon}
                          color="primary"
                          text={utils.string.t('risks.refer')}
                          nestedClasses={{ icon: classes.icon }}
                          onClick={() => handleRequestDismissIssues(quoteState.id)}
                          disabled={isWaiting}
                        />
                      </Box>
                    ) : null}
                    {issuesData?.quoteIssues?.length > 0 ? (
                      <Box mb={1}>
                        {canCurrentUserDismissIssues && !isRejected ? (
                          <Button
                            data-testid="decline-quote-button"
                            size="xsmall"
                            icon={BlockIcon}
                            color="neutral"
                            text={utils.string.t('risks.decline')}
                            nestedClasses={{ btn: classes.btnMarginLeft, icon: classes.icon }}
                            onClick={() => handleDeclineRiskQuote(quoteState.id)}
                            disabled={isWaiting || isLoadingAcceptQuote}
                          />
                        ) : null}
                        <Button
                          data-testid="issues-quote-button"
                          size="xsmall"
                          icon={InfoOutlinedIcon}
                          color="primary"
                          danger
                          text={utils.string.t('risks.issues')}
                          nestedClasses={{ btn: classes.btnMarginLeft, icon: classes.icon }}
                          onClick={(e) => (issuesData?.quoteIssues?.length > 1 ? toggleDrawer(true) : handleIssuesPopoverClick(e))}
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Box p={2} maxWidth={340}>
                            <Box value="issues" data-testid="tab-content-issues">
                              {issuesData?.hasIssues && (
                                <QuoteIssues
                                  riskIssues={issuesData?.issues}
                                  quoteIssues={issuesData?.quoteIssues}
                                  insuredSanctionsCheckResult={issuesData?.insuredSanctionsCheckResult}
                                  reInsuredSanctionsCheckResult={issuesData?.reInsuredSanctionsCheckResult}
                                  canCurrentUserDismissIssues={canCurrentUserDismissIssues}
                                  handleRiskRefresh={handleRiskRefresh}
                                  quoteId={quote?.id}
                                />
                              )}
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </CardActions>
            ) : null}
            {isNTU ? (
              <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                <CardActions disableSpacing classes={{ root: classes.cardActions }}>
                  <Box px={1} display="flex" width="100%" flexDirection="row" justifyContent="end">
                    <Button
                      data-testid="reactivate-quote-button"
                      size="xsmall"
                      icon={UpdateIcon}
                      color="primary"
                      text={utils.string.t('risks.reactivate')}
                      nestedClasses={{ icon: classes.icon }}
                      onClick={() => handleReactivateRiskQuote(quoteState.id)}
                      disabled={isLoadingReActivate}
                    />
                  </Box>
                </CardActions>
              </Restricted>
            ) : null}
          </>
        )}
      </Card>
    </>
  );
};
